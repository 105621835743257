var UserProfile = (function() {

    var getUser = function() {
        var data = localStorage.getItem('user')
        if (data == null)  {
            return {};
        }
        else
            return JSON.parse(data);
        
    };
  
    var setUser = function(data) {
        localStorage.setItem('user', JSON.stringify(data));
    };

    var clearUser = function() {
        localStorage.removeItem('user');
    };

    return {
        getUser: getUser,
        setUser: setUser,
        clearUser:clearUser
    }
  
})();
  
export default UserProfile;

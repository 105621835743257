import { Table, Card } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import AlertingBadge from './AlertingBadge'

const PositionsSummary = ( {positions} ) => {
    return (
        <Card>
            <Card.Header>Positions Summary</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th className='text-center'>Available Margin</th>
                                <th className='text-center'>Margin Usage</th>
                                <th className='text-center'>Unrealized PnL</th>
                                <th className='text-center'>Realized 30 Days PnL</th>
                                <th className='text-center'>Realized 60 Days PnL</th>
                                <th className='text-center'>Realized 180 Days PnL</th>
                                <th className='text-center'>Realized YtD PnL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-center'><Currency>{positions.availableMargin}</Currency></td>
                                <td className='text-center'><Currency>{positions.marginUsage}</Currency></td>
                                <td className='text-center'>
                                    {positions.unrealizedPnL > 0 ? (
                                        <AlertingBadge value={positions.unrealizedPnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={positions.unrealizedPnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                    }
                                </td>
                                <td className='text-center'>
                                    {positions.realized30DaysPnL > 0 ? (
                                        <AlertingBadge value={positions.realized30DaysPnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={positions.realized30DaysPnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                    }
                                </td>
                                <td className='text-center'>
                                    {positions.realized90DaysPnL > 0 ? (
                                        <AlertingBadge value={positions.realized90DaysPnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={positions.realized90DaysPnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                    }
                                </td>
                                <td className='text-center'>
                                    {positions.realized180DaysPnL > 0 ? (
                                        <AlertingBadge value={positions.realized180DaysPnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={positions.realized180DaysPnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                    }
                                </td>
                                <td className='text-center'>
                                    {positions.realizedYtDPnL > 0 ? (
                                        <AlertingBadge value={positions.realizedYtDPnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={positions.realizedYtDPnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default PositionsSummary
// import React from 'react'

import { Row, Col } from 'react-bootstrap'

import UnderlyingPanel from '../components/UnderlyingPanel'
import VolatilityImply from '../components/VolatilityImply'


const Volatility = ( {serverStatus, underlyings, onSelectUnderlying, selectedUnderlying, basicMarketdData, timeState} ) => {
    return (
        <div className="main-frame">
            <Row>
                <Col>
                    <UnderlyingPanel serverStatus={serverStatus}
                                        underlyings={underlyings} 
                                        onSelectUnderlying={onSelectUnderlying} 
                                        selectedUnderlying={selectedUnderlying}
                                        basicMarketdData={basicMarketdData}
                                        timeState={timeState}
                                        >
                    </UnderlyingPanel>
                </Col>
            </Row>
            {Object.keys(selectedUnderlying).length !== 0 ? (
                <Row>
                    <Col>
                        <VolatilityImply serverStatus={serverStatus}>
                        </VolatilityImply>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            ) : ('')}
        </div>
    )
}

export default Volatility

import { Alert } from 'react-bootstrap'

const Alertbanner = ( {Response} ) => {
    return (
        <Alert variant={Response.Result}>
            <div className="text-center">{Response.Message}</div>
        </Alert>
    )
}

export default Alertbanner
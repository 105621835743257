import { Row, Col } from 'react-bootstrap'

import UnderlyingPanel from '../components/UnderlyingPanel'
import BuyOptionsComboFinder from '../components/BuyOptionsComboFinder'
import Alertbanner from '../components/Alertbanner'
import StandardDeviation from '../components/StandardDeviation'


const BuyOptions = ( {bookTradeResponse, serverStatus, underlyings, onSelectUnderlying, selectedUnderlying, basicMarketdData, timeState, optionsExpiries, onSelectExpiry, selectedExpiry, iv, standardDeviationData, riskFreeRate, 
                        changeType, setChangeType, changePerc, setChangePerc, atDateList, selectedAtDate, setSelectedAtDate, volatility, setVolatility, risk, setRisk,
                        buyTrades, setBuyTrades, onFindBuyTrade, spinnerFindTrade, setBookTradeResponse} ) => {
    return (
        <div className="main-frame">
            {Object.keys(bookTradeResponse).length !== 0 ? (<Alertbanner Response={bookTradeResponse}></Alertbanner>) : ('')}
            <Row>
                <Col>
                    <UnderlyingPanel serverStatus={serverStatus}
                                        underlyings={underlyings} 
                                        onSelectUnderlying={onSelectUnderlying} 
                                        selectedUnderlying={selectedUnderlying}
                                        basicMarketdData={basicMarketdData}
                                        timeState={timeState}
                                        >
                    </UnderlyingPanel>
                </Col>
            </Row>
            {Object.keys(selectedUnderlying).length !== 0 ? (
                <Row>
                    <Col>
                        <BuyOptionsComboFinder serverStatus={serverStatus}
                                                    optionsExpiries={optionsExpiries} 
                                                    onSelectExpiry={onSelectExpiry} 
                                                    selectedExpiry={selectedExpiry}
                                                    iv={iv}
                                                    riskFreeRate={riskFreeRate}
                                                    changeType={changeType}
                                                    setChangeType={setChangeType}
                                                    changePerc={changePerc}
                                                    setChangePerc={setChangePerc}
                                                    atDateList={atDateList}
                                                    selectedAtDate={selectedAtDate}
                                                    setSelectedAtDate={setSelectedAtDate}
                                                    volatility={volatility}
                                                    setVolatility={setVolatility}
                                                    risk={risk}
                                                    setRisk={setRisk}
                                                    buyTrades={buyTrades}
                                                    setBuyTrades={setBuyTrades}
                                                    onFindBuyTrade={onFindBuyTrade}
                                                    spinnerFindTrade={spinnerFindTrade}
                                                    setBookTradeResponse={setBookTradeResponse}>
                        </BuyOptionsComboFinder>
                    </Col>
                </Row>
            ) : ('')}
            {Object.keys(selectedExpiry).length !== 0 ? (
                <Row>
                    <Col>
                        <StandardDeviation standardDeviationData={standardDeviationData}>
                        </StandardDeviation>
                    </Col>
                </Row>
            ) : ('')}
        </div>
    )
}

export default BuyOptions
import { Card, Table } from 'react-bootstrap'


const StandardDeviation = ( {standardDeviationData} ) => {
  return (
    <Card>
            <Card.Header>Standard Deviations</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th>-2 SD</th>
                                <th>-1 SD</th>
                                <th>+1 SD</th>
                                <th>+2 SD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{Object.keys(standardDeviationData).length !== 0 ? (standardDeviationData.minus2) : ('0')}</td>
                                <td>{Object.keys(standardDeviationData).length !== 0 ? (standardDeviationData.minus1) : ('0')}</td>
                                <td>{Object.keys(standardDeviationData).length !== 0 ? (standardDeviationData.plus1) : ('0')}</td>
                                <td>{Object.keys(standardDeviationData).length !== 0 ? (standardDeviationData.plus2) : ('0')}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
  )
}

export default StandardDeviation
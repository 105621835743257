// import React from 'react'
import { Row, Col } from 'react-bootstrap'

import PositionsSummary from '../components/PositionsSummary'
import PositionsDetail from'../components/PositionsDetail'
import PositionsClosed from '../components/PositionsClosed'
import Alertbanner from '../components/Alertbanner'


const Positions = ( {serverStatus, positions, updateTradeResponse, setUpdateTradeResponse, closeTradeResponse, setCloseTradeResponse} ) => {
    return (
        <div className=".main-frame">
            { Object.keys(positions).length !== 0 ? (
                <div>
                    {Object.keys(updateTradeResponse).length !== 0 ? (<Alertbanner Response={updateTradeResponse}></Alertbanner>) : ('')}
                    {Object.keys(closeTradeResponse).length !== 0 ? (<Alertbanner Response={closeTradeResponse}></Alertbanner>) : ('')}
                    <Row>
                        <Col>
                            <PositionsSummary positions={positions}></PositionsSummary>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PositionsDetail serverStatus={serverStatus} positions={positions} setUpdateTradeResponse={setUpdateTradeResponse} setCloseTradeResponse={setCloseTradeResponse}></PositionsDetail>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PositionsClosed positions={positions}></PositionsClosed>
                        </Col>
                    </Row>
                </div>
                ) : ('')
            }
        </div>
    )
}

export default Positions
import { Container, Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import httpInstance from "../httpClient"
import UserProfile from "../userProfile"
import getCookie from "../getCookie"


const BookTrade = ( { selectedTrade, setSelectedTrade, setBookTradeResponse} ) => {
    const handleClose = () => setSelectedTrade({});

    const onChangePrice = (event) => {
        selectedTrade.instrument.legs[event.target.id].openPrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTrade.instrument.legs[event.target.id].fees = event.target.value
    }

    const onChangeTradeDateTime = (event) => {
        selectedTrade.tradeDateTime = event.target.value
    }

    const handleBookTrade = () => {
        selectedTrade.id = null
        httpInstance.post('/bookTrade', {
            selectedTrade
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setBookTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTrade({});

        setTimeout(() => {
            setBookTradeResponse({})
        }, 2000);
    }

    const handleBookPendingTrade = () => {
        selectedTrade.id = null
        httpInstance.post('/bookPendingTrade', {
            selectedTrade
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setBookTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTrade({});

        setTimeout(() => {
            setBookTradeResponse({})
        }, 2000);
    }
    
    const handleExecuteTrade = () => {
        selectedTrade.id = null
        httpInstance.post('/executeTrade', {
            selectedTrade
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setBookTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTrade({});

        setTimeout(() => {
            setBookTradeResponse({})
        }, 2000);
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedTrade.quantity} {selectedTrade.instrument.definiton} @ {selectedTrade.instrument.openPrice}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Table striped size="sm">
                                <thead className="table-primary">
                                    <tr>
                                        <th className='align-middle'>Expiry</th>
                                        <th className='align-middle'>Side</th>
                                        <th className='align-middle'>Type</th>
                                        <th className='align-middle'>Strike</th>
                                        <th className='align-middle' style={{width: "15%"}}>Price</th>
                                        <th className='align-middle' style={{width: "15%"}}>Fees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedTrade.instrument.legs.map((leg, index) => (
                                        <tr key={index}>
                                            <td className='align-middle'>{leg.expiry}</td>
                                            <td className='align-middle'>{leg.side}</td>
                                            <td className='align-middle'>{leg.type}</td>
                                            <td className='align-middle'>{leg.strike}</td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" defaultValue={leg.openPrice} onChange={onChangePrice}/>
                                                </Form.Group>
                                            </td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" defaultValue={leg.fees} onChange={onChangeFees}/>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="tradeDateTime">Trade Date & Time</InputGroup.Text>
                                    <Form.Control aria-label="tradeDateTime" defaultValue={selectedTrade.tradeDateTime} onChange={onChangeTradeDateTime}/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleBookTrade}>
                        Book Trade
                    </Button>
                    <Button variant="primary" onClick={handleBookPendingTrade}>
                        Book Pending Trade
                    </Button>
                    <Button variant="primary" onClick={handleExecuteTrade}>
                        Execute Trade
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BookTrade
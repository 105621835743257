import { Container, Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import httpInstance from "../httpClient"
import UserProfile from "../userProfile"
import getCookie from "../getCookie"


const CloseTrade = ( { selectedTradeToClose, setSelectedTradeToClose, setCloseTradeResponse} ) => {
    const handleClose = () => setSelectedTradeToClose({});

    const onChangePrice = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].closePrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].additionnalFees = event.target.value
    }

    const onChangeClosedateTime = (event) => {
        selectedTradeToClose.instrument.legs[event.target.id].closeDateTime = event.target.value
    }

    const handleBookClose = () => {
        // selectedTradeToClose.id = null
        httpInstance.post('/bookCloseTrade', {
            selectedTradeToClose
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setCloseTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTradeToClose({});

        setTimeout(() => {
            setCloseTradeResponse({})
        }, 2000);
    }

    const handleExecuteClose = () => {
        // selectedTradeToClose.id = null
        httpInstance.post('/executeCloseTrade', {
            selectedTradeToClose
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setCloseTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTradeToClose({});

        setTimeout(() => {
            setCloseTradeResponse({})
        }, 2000);
    }

    const handleDeleteTrade = () => {
        // selectedTradeToClose.id = null
        httpInstance.post('/deletePendingTrade', {
            'id': selectedTradeToClose.id
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setCloseTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTradeToClose({});

        setTimeout(() => {
            setCloseTradeResponse({})
        }, 2000);
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedTradeToClose.instrument.definiton}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Table striped size="sm">
                                <thead className="table-primary">
                                    <tr>
                                        <th className='align-middle'>Status</th>
                                        <th className='align-middle'>Expiry</th>
                                        <th className='align-middle'>Side</th>
                                        <th className='align-middle'>Type</th>
                                        <th className='align-middle'>Strike</th>
                                        <th className='align-middle' style={{width: "15%"}}>Open Price</th>
                                        <th className='align-middle' style={{width: "15%"}}>Open Fees</th>
                                        <th className='align-middle' style={{width: "15%"}}>Close Price</th>
                                        <th className='align-middle' style={{width: "15%"}}>Closing Fees</th>
                                        <th className='align-middle' style={{width: "25%"}}>Close Date&Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedTradeToClose.instrument.legs.map((leg, index) => (
                                        <tr key={index}>
                                            <td className='align-middle'>{leg.status}</td>
                                            <td className='align-middle'>{leg.expiry}</td>
                                            <td className='align-middle'>{leg.side}</td>
                                            <td className='align-middle'>{leg.type}</td>
                                            <td className='align-middle'>{leg.strike}</td>
                                            <td className='align-middle'>$ {leg.openPrice}</td>
                                            <td className='align-middle'>$ {leg.fees}</td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" onChange={onChangePrice}/>
                                                </Form.Group>
                                            </td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" onChange={onChangeFees}/>
                                                </Form.Group>
                                            </td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" onChange={onChangeClosedateTime}/>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="tradeDateTime">Open Trade Date&Time</InputGroup.Text>
                                    <Form.Control aria-label="tradeDateTime" defaultValue={selectedTradeToClose.tradeDateTime} disabled/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {selectedTradeToClose.status === 'Pending' ? (
                        <Button variant="primary" onClick={handleDeleteTrade}>
                            Delete Pending Trade
                        </Button>
                    ) : (
                        <div>
                            <Button variant="secondary" onClick={handleBookClose}>
                                Book Close Trade
                            </Button>
                            <Button variant="primary" onClick={handleExecuteClose}>
                                Execute Closing Trade
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CloseTrade

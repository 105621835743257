import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter  } from "react-router-dom";
import { IntlProvider } from "react-intl-number-format"

import "./scss/custom.css";

import App from './App';


const intlConfig = {
    locale: "en-US",
    options: {
        currency: "USD",
        maximumFractionDigits: 2
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <IntlProvider config={intlConfig}>
                <App />
            </IntlProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
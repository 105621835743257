import { Row, Col } from 'react-bootstrap'

import UnderlyingPanel from '../components/UnderlyingPanel'
import SellOptionsComboFinder from '../components/SellOptionsComboFinder'
import Alertbanner from '../components/Alertbanner'
import StandardDeviation from '../components/StandardDeviation'


const SellOptions = ( {bookTradeResponse, serverStatus, underlyings, onSelectUnderlying, selectedUnderlying, basicMarketdData, timeState, optionsExpiries, onSelectExpiry, selectedExpiry, 
                        iv, standardDeviationData, riskFreeRate, putRisk, setPutRisk, callRisk, setCallRisk, marginTarget, setMarginTarget, volatility, setVolatility, 
                        sellTrades, setSellTrades, onFindSellTrade, spinnerFindTrade, setBookTradeResponse} ) => {
    return (
        <div className="main-frame">
            {Object.keys(bookTradeResponse).length !== 0 ? (<Alertbanner Response={bookTradeResponse}></Alertbanner>) : ('')}
            <Row>
                <Col>
                    <UnderlyingPanel serverStatus={serverStatus}
                                        underlyings={underlyings} 
                                        onSelectUnderlying={onSelectUnderlying} 
                                        selectedUnderlying={selectedUnderlying}
                                        basicMarketdData={basicMarketdData}
                                        timeState={timeState}
                                        >
                    </UnderlyingPanel>
                </Col>
            </Row>
            {Object.keys(selectedUnderlying).length !== 0 ? (
                <Row>
                    <Col>
                        <SellOptionsComboFinder serverStatus={serverStatus}
                                                    optionsExpiries={optionsExpiries} 
                                                    onSelectExpiry={onSelectExpiry} 
                                                    selectedExpiry={selectedExpiry}
                                                    iv={iv}
                                                    riskFreeRate={riskFreeRate}
                                                    putRisk={putRisk}
                                                    setPutRisk={setPutRisk}
                                                    callRisk={callRisk}
                                                    setCallRisk={setCallRisk}
                                                    marginTarget={marginTarget}
                                                    setMarginTarget={setMarginTarget}
                                                    volatility={volatility}
                                                    setVolatility={setVolatility}
                                                    sellTrades={sellTrades}
                                                    setSellTrades={setSellTrades}
                                                    onFindSellTrade={onFindSellTrade}
                                                    spinnerFindTrade={spinnerFindTrade}
                                                    setBookTradeResponse={setBookTradeResponse}>
                        </SellOptionsComboFinder>
                    </Col>
                </Row>
            ) : ('')}
            {Object.keys(selectedExpiry).length !== 0 ? (
                <Row>
                    <Col>
                        <StandardDeviation standardDeviationData={standardDeviationData}>
                        </StandardDeviation>
                    </Col>
                </Row>
            ) : ('')}
        </div>
    )
}

export default SellOptions

import { Table, Card } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import AlertingBadge from './AlertingBadge'


const PositionsClosed = ( {positions} ) => {
    return (
        <Card>
            <Card.Header>Closed Positions Details</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th className='text-start positions-details-table' style={{width: "5%"}}>Trade ID</th>
                                <th className='text-start positions-details-table'>Trade Date</th>
                                <th className='text-start positions-details-table'>Close date</th>
                                <th className='text-start positions-details-table'>Symbol</th>
                                <th className='text-start positions-details-table'>Definition</th>
                                <th className='text-start positions-details-table'>Qty</th>
                                <th className='text-start positions-details-table'>PnL</th>
                                <th className='text-start positions-details-table'>Return</th>
                                <th className='text-start positions-details-table'>Fees</th>
                                <th className='text-start positions-details-table'>Margin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {positions.ClosedTradesList.map((trade) => (     
                                <tr key={trade.id}>
                                    <td className='text-start positions-details-table'>{trade.id}</td>
                                    <td className='text-start positions-details-table'>{trade.tradeDateTime}</td>
                                    <td className='text-start positions-details-table'>{trade.closeTradeDateTime}</td>
                                    <td className='text-start positions-details-table'>{trade.instrument.symbol}</td>
                                    <td className='text-start positions-details-table'>{trade.instrument.definiton}</td>
                                    <td className='text-start positions-details-table'>{trade.quantity}</td>
                                    <td className='text-start positions-details-table'>
                                        {trade.PnL > 0 ? (
                                            <AlertingBadge value={trade.PnL} alertingLevel="success" unit="cur"></AlertingBadge>
                                        ) : (
                                            <AlertingBadge value={trade.PnL} alertingLevel="danger" unit="cur"></AlertingBadge>
                                        )
                                        }
                                    </td>
                                    <td className='text-start positions-details-table'>{trade.perf} %</td>
                                    <td className='text-start positions-details-table'><Currency>{trade.fees}</Currency></td>
                                    <td className='text-start positions-details-table'><Currency>{trade.marginUsage}</Currency></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default PositionsClosed
// import React from 'react'
import { Table, Dropdown, Card } from 'react-bootstrap'

import AlertingBadge from './AlertingBadge'


const UnderlyingPanel = ( {serverStatus, underlyings, onSelectUnderlying, selectedUnderlying, basicMarketdData, timeState} ) => {
    const handleSelect = (id) => {
        if (serverStatus.value === 2) {
            onSelectUnderlying(id);
        }
    }

    return (
        <Card>
            <Card.Header>Underlying Panel</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th style={{width: "20%"}}>{Object.keys(selectedUnderlying).length !== 0 ? (selectedUnderlying.longName) : ('')}</th>
                                <th className='text-center' style={{width: "10%"}}>Status</th>
                                <th className='text-end' style={{width: "10%"}}>Bid Size</th>
                                <th className='text-center' style={{width: "10%"}}>Bid</th>
                                <th className='text-center' style={{width: "10%"}}>Ask</th>
                                <th className='text-start' style={{width: "10%"}}>Ask Size</th>
                                <th className='text-center' style={{width: "10%"}}>Last</th>
                                <th className='text-center' style={{width: "10%"}}>Close</th>
                                <th className='text-center' style={{width: "10%"}}>Local Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='align-middle'>
                                    <Dropdown onSelect={handleSelect}>
                                        <Dropdown.Toggle variant="secondary" size="sm">
                                            {Object.keys(selectedUnderlying).length === 0 ? ('Select Underlying') : (selectedUnderlying.symbol+'-'+selectedUnderlying.exchange+'-'+selectedUnderlying.currency)}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {underlyings.map((underlying) => (
                                                <Dropdown.Item key={underlying.id} eventKey={underlying.id}>{underlying.symbol}-{underlying.exchange}-{underlying.currency}</Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                                <td className='align-middle'>
                                    {Object.keys(basicMarketdData).length !== 0 ? (
                                        basicMarketdData.status === 'Open' ? (
                                            <AlertingBadge value={basicMarketdData.status} alertingLevel="success" unit=""></AlertingBadge>
                                        ) : ( basicMarketdData.status === 'AfterHours' ? (
                                                <AlertingBadge value={basicMarketdData.status} alertingLevel="warning" unit=""></AlertingBadge>
                                                ) : (
                                                    <AlertingBadge value={basicMarketdData.status} alertingLevel="danger" unit=""></AlertingBadge>
                                                )
                                            )
                                        ) : ('')
                                    }
                                </td>
                                <td className='text-end align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.bidSize) : ('')}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.bid) : ('')}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.ask) : ('')}</td>
                                <td className='text-start align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.askSize) : ('')}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.last) : ('')}</td>
                                <td className='text-center align-middle'>{Object.keys(basicMarketdData).length !== 0 ? (basicMarketdData.close) : ('')}</td>
                                <td className='text-center align-middle'>
                                    {timeState !== 0 ? (
                                        timeState.toLocaleString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true,
                                        })
                                    ) : ('')}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default UnderlyingPanel
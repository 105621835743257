const getCookie = function( {name} ) {
    // console.log("getCookie");
    // console.log(document.cookie);
    const parts = document.cookie.split(`${name}=`);
    // console.log(parts);
    if (parts.length > 0) {
        var cookie = parts.pop().split(';').shift().split('=')[1];
        // console.log(cookie);
        return cookie
    }
}

export default getCookie

import { Container, Form, Button, Table, Modal, InputGroup, Row, Col } from 'react-bootstrap'

import httpInstance from "../httpClient"
import UserProfile from "../userProfile"
import getCookie from "../getCookie"


const UpdateTrade = ( { selectedTradeToUpdate, setSelectedTradeToUpdate, setUpdateTradeResponse} ) => {
    const handleClose = () => setSelectedTradeToUpdate({});

    const onChangePrice = (event) => {
        selectedTradeToUpdate.instrument.legs[event.target.id].openPrice = event.target.value
    }

    const onChangeFees = (event) => {
        selectedTradeToUpdate.instrument.legs[event.target.id].fees = event.target.value
    }

    const onChangeQuantity = (event) => {
        selectedTradeToUpdate.quantity = event.target.value
    }

    const handleUpdateTrade = () => {
        httpInstance.post('/updateTrade', {
            selectedTradeToUpdate
        },{
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            }
        })
        .then(function (response) {
            setUpdateTradeResponse(response.data);
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log('User Logged Out')
                    UserProfile.clearUser()
                }
            }
            console.log(error);
        });

        setSelectedTradeToUpdate({});

        setTimeout(() => {
            setUpdateTradeResponse({})
        }, 2000);
    }

    return (
        <div>
            <Modal show="true" size="lg" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedTradeToUpdate.instrument.definiton}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Table striped size="sm">
                                <thead className="table-primary">
                                    <tr>
                                        <th className='align-middle'>Status</th>
                                        <th className='align-middle'>Expiry</th>
                                        <th className='align-middle'>Side</th>
                                        <th className='align-middle'>Type</th>
                                        <th className='align-middle'>Strike</th>
                                        <th className='align-middle' style={{width: "15%"}}>Open Price</th>
                                        <th className='align-middle' style={{width: "15%"}}>Open Fees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedTradeToUpdate.instrument.legs.map((leg, index) => (
                                        <tr key={index}>
                                            <td className='align-middle'>{leg.status}</td>
                                            <td className='align-middle'>{leg.expiry}</td>
                                            <td className='align-middle'>{leg.side}</td>
                                            <td className='align-middle'>{leg.type}</td>
                                            <td className='align-middle'>{leg.strike}</td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" defaultValue={leg.openPrice} onChange={onChangePrice}/>
                                                </Form.Group>
                                            </td>
                                            <td className='align-middle'>
                                                <Form.Group controlId={index}>
                                                    <Form.Control size="sm" defaultValue={leg.fees} onChange={onChangeFees}/>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="quantity">Quantity</InputGroup.Text>
                                    <Form.Control aria-label="quantity" defaultValue={selectedTradeToUpdate.quantity} onChange={onChangeQuantity}/>
                                </InputGroup>
                            </Col>
                            <Col xs={6}>
                                <InputGroup size="sm" className="mb-3">
                                    <InputGroup.Text id="tradeDateTime">Open Trade Date&Time</InputGroup.Text>
                                    <Form.Control aria-label="tradeDateTime" defaultValue={selectedTradeToUpdate.tradeDateTime} disabled/>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUpdateTrade}>
                        Update Trade
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateTrade

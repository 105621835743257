import { Currency } from 'react-intl-number-format'

const AlertingBadge = ( {value, alertingLevel, unit} ) => {

    const defineAlertingLevel = () => {
        if (alertingLevel === 'success') {
            return('bg-success text-white text-center rounded px-2')
        }
        else if (alertingLevel === 'warning') {
            return('bg-warning text-white text-center rounded px-2')
        }
        else if (alertingLevel === 'info') {
            return('bg-info text-white text-center rounded px-2')
        }
        else if (alertingLevel === 'light') {
            return('bg-light text-white text-center rounded px-2')
        }
        else if (alertingLevel === 'dark') {
            return('bg-dark text-white text-center rounded px-2')
        }
        else {
            return('bg-danger text-white text-center rounded px-2')
        }
    }

    return (
        <div>
            {unit === "cur" ? (
                <div className={defineAlertingLevel()}><Currency>{value}</Currency></div>
                ) : ( unit === "perc" ? (
                    <div className={defineAlertingLevel()}>{value} %</div>
                    ) : (
                        <div className={defineAlertingLevel()}>{value}</div>
                    )
                )
            }
        </div>
    )
}

export default AlertingBadge
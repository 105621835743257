import { Row, Col } from 'react-bootstrap'

import OrderList from '../components/OrderList'
import ExecutionsList from '../components/ExecutionsList'


const Trades = ( {orders, executions} ) => {
  return (
    <div className="main-frame">
        <div>
            <Row>
                <Col>
                    <OrderList orders={orders}></OrderList>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ExecutionsList executions={executions}></ExecutionsList>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Trades
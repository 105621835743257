import { useState } from "react"
import { Card, Dropdown, Row, Form, Button, Table, Spinner } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

import BookTrade from './BookTrade'


const SellOptionsComboFinder = ( {serverStatus, optionsExpiries, onSelectExpiry, selectedExpiry, 
                                        iv, riskFreeRate, putRisk, setPutRisk, callRisk, setCallRisk, marginTarget, setMarginTarget, volatility, setVolatility, 
                                        sellTrades, setSellTrades, onFindSellTrade, spinnerFindTrade, setBookTradeResponse} ) => {
    
    const [selectedTrade, setSelectedTrade] = useState({})

    const handleSelect = (id) => {
        if (serverStatus.value === 2) {
            onSelectExpiry(id);
            setSellTrades([]);
        }
    }

    const onSelectBookTrade = (event) => {
        if (serverStatus.value === 2) {
            const id = event.target.id;
            setSelectedTrade(sellTrades[id])
        }
    }

    return (
        <Card>
            <Card.Header>Combo Finder : Selling Options</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Row className="combo-finder-selling-options-menu">
                        <Table size="sm">
                            <thead>
                                <tr>
                                    <th style={{width: "15%"}}></th>
                                    <th>Current IV (%)</th>
                                    <th>Risk Free Rate (%)</th>
                                    <th>Put Risk (%)</th>
                                    <th>Call Risk (%)</th>
                                    <th>Margin Target ($)</th>
                                    <th>Volatility (%)</th>
                                    <th  style={{width: "10%"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="0">
                                    <td>
                                        <Dropdown onSelect={handleSelect}>
                                            <Dropdown.Toggle variant="secondary" size="sm">
                                                {Object.keys(selectedExpiry).length === 0 ? ('Select Expiry') : (selectedExpiry.name+' - '+selectedExpiry.daysToExpiry+' Days Left')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {optionsExpiries.map((expiry) => (
                                                    <Dropdown.Item key={expiry.id} eventKey={expiry.id}>{expiry.name} - {expiry.daysToExpiry} Days Left</Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" placeholder={iv.value} disabled/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" placeholder={riskFreeRate.value} disabled/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={putRisk} onChange={e => setPutRisk(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={callRisk} onChange={e => setCallRisk(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={marginTarget} onChange={e => setMarginTarget(e.target.value)}/>
                                    </td>
                                    <td>
                                        <Form.Control size="sm" value={volatility} onChange={e => setVolatility(e.target.value)}/>
                                    </td>
                                    <td>
                                        {Object.keys(selectedExpiry).length === 0 ? (
                                            <Button variant="secondary" size="sm" onClick={onFindSellTrade} disabled>Find Trades</Button>
                                        ) : ( spinnerFindTrade ? (
                                                <Button variant="secondary" size="sm" disabled>
                                                    <Spinner animation="border" variant="light" size="sm"/>
                                                </Button>
                                            ) : (
                                                <Button variant="secondary" size="sm" onClick={onFindSellTrade}>Find Trades</Button>
                                            )
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                    { Object.keys(sellTrades).length !== 0 ? (
                        <Row>
                            <Table striped size="sm">
                                <thead className="table-primary">
                                    <tr>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Combo</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Credit</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Quantity</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Est. Fees</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Margin</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Return</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Risk/Return</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Deviation</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Win Prob</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'>Legs Probabilities</th>
                                        <th className='SellOptionsComboFinder-results-table align-middle'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sellTrades.map((trade, index) => (     
                                        <tr key={index}>            
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.instrument.definiton}</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'><Currency>{trade.netPrice}</Currency></td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.quantity}</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'><Currency>{trade.fees}</Currency></td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'><Currency>{trade.marginUsage}</Currency></td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.perf} %</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.return_risk_ratio} %</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.instrument.deviation}</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.instrument.probability} %</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>{trade.instrument.legsProbabilities}</td>
                                            <td className='SellOptionsComboFinder-results-table align-middle'>
                                                <Button variant="primary" size="sm" className='SellOptionsComboFinder-results-table-button' id={index} onClick={onSelectBookTrade}>Book</Button>
                                            </td>
                                        </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Row>
                    ) : (
                        ""
                    )}
                </Card.Text>
            </Card.Body>
            {Object.keys(selectedTrade).length !== 0 ? (
                <BookTrade selectedTrade={selectedTrade} setSelectedTrade={setSelectedTrade} setBookTradeResponse={setBookTradeResponse}></BookTrade>
            ) : ('')}
        </Card>
    )
}

export default SellOptionsComboFinder

import { Card, Row, Col } from 'react-bootstrap'
import { ResponsiveLine} from '@nivo/line' 



function VolatilityImply( {serverStatus} ) {
    const data = [
        {
          "id": "japan",
          "color": "hsl(345, 70%, 50%)",
          "data": [
            {
              "x": "plane",
              "y": 235
            },
            {
              "x": "helicopter",
              "y": 298
            },
            {
              "x": "boat",
              "y": 227
            },
            {
              "x": "train",
              "y": 97
            },
            {
              "x": "subway",
              "y": 106
            },
            {
              "x": "bus",
              "y": 113
            },
            {
              "x": "car",
              "y": 43
            },
            {
              "x": "moto",
              "y": 296
            },
            {
              "x": "bicycle",
              "y": 204
            },
            {
              "x": "horse",
              "y": 264
            },
            {
              "x": "skateboard",
              "y": 60
            },
            {
              "x": "others",
              "y": 33
            }
          ]
        },
        {
          "id": "france",
          "color": "hsl(85, 70%, 50%)",
          "data": [
            {
              "x": "plane",
              "y": 190
            },
            {
              "x": "helicopter",
              "y": 225
            },
            {
              "x": "boat",
              "y": 51
            },
            {
              "x": "train",
              "y": 122
            },
            {
              "x": "subway",
              "y": 37
            },
            {
              "x": "bus",
              "y": 122
            },
            {
              "x": "car",
              "y": 174
            },
            {
              "x": "moto",
              "y": 94
            },
            {
              "x": "bicycle",
              "y": 253
            },
            {
              "x": "horse",
              "y": 179
            },
            {
              "x": "skateboard",
              "y": 204
            },
            {
              "x": "others",
              "y": 3
            }
          ]
        },
        {
          "id": "us",
          "color": "hsl(322, 70%, 50%)",
          "data": [
            {
              "x": "plane",
              "y": 174
            },
            {
              "x": "helicopter",
              "y": 199
            },
            {
              "x": "boat",
              "y": 123
            },
            {
              "x": "train",
              "y": 96
            },
            {
              "x": "subway",
              "y": 28
            },
            {
              "x": "bus",
              "y": 46
            },
            {
              "x": "car",
              "y": 72
            },
            {
              "x": "moto",
              "y": 185
            },
            {
              "x": "bicycle",
              "y": 63
            },
            {
              "x": "horse",
              "y": 129
            },
            {
              "x": "skateboard",
              "y": 99
            },
            {
              "x": "others",
              "y": 126
            }
          ]
        },
        {
          "id": "germany",
          "color": "hsl(121, 70%, 50%)",
          "data": [
            {
              "x": "plane",
              "y": 86
            },
            {
              "x": "helicopter",
              "y": 263
            },
            {
              "x": "boat",
              "y": 100
            },
            {
              "x": "train",
              "y": 216
            },
            {
              "x": "subway",
              "y": 226
            },
            {
              "x": "bus",
              "y": 126
            },
            {
              "x": "car",
              "y": 161
            },
            {
              "x": "moto",
              "y": 176
            },
            {
              "x": "bicycle",
              "y": 291
            },
            {
              "x": "horse",
              "y": 234
            },
            {
              "x": "skateboard",
              "y": 299
            },
            {
              "x": "others",
              "y": 92
            }
          ]
        },
        {
          "id": "norway",
          "color": "hsl(90, 70%, 50%)",
          "data": [
            {
              "x": "plane",
              "y": 66
            },
            {
              "x": "helicopter",
              "y": 204
            },
            {
              "x": "boat",
              "y": 254
            },
            {
              "x": "train",
              "y": 33
            },
            {
              "x": "subway",
              "y": 10
            },
            {
              "x": "bus",
              "y": 238
            },
            {
              "x": "car",
              "y": 185
            },
            {
              "x": "moto",
              "y": 36
            },
            {
              "x": "bicycle",
              "y": 246
            },
            {
              "x": "horse",
              "y": 133
            },
            {
              "x": "skateboard",
              "y": 211
            },
            {
              "x": "others",
              "y": 237
            }
          ]
        }
    ]

    return (
        <Card>
            <Card.Header>Implied Volatility</Card.Header>
            <Card.Body>
                <Card.Text as="div">
                    <Row>
                        <Col>
                            toto
                            <ResponsiveLine
                                data={data}
                                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{
                                    type: 'linear',
                                    min: 'auto',
                                    max: 'auto',
                                    stacked: true,
                                    reverse: false
                                }}
                                yFormat=" >-.2f"
                                // width="700"
                                height="600"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'transportation',
                                    legendOffset: 36,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'count',
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                }}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 80,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default VolatilityImply

import { Table, Card } from 'react-bootstrap'
import { Currency } from 'react-intl-number-format'

const OrderList = ( {orders} ) => {
  return (
    <Card>
        <Card.Header>Orders Details</Card.Header>
        <Card.Body>
            <Card.Text as="div">
                <Table size="sm" hover>
                    <thead>
                        <tr>
                            <th className='text-start positions-details-table'>Time</th>
                            <th className='text-start positions-details-table'>Perm ID</th>
                            <th className='text-start positions-details-table'>Order ID</th>
                            <th className='text-start positions-details-table'>conId</th>
                            <th className='text-start positions-details-table'>Action</th>
                            <th className='text-start positions-details-table'>Total Qty</th>
                            <th className='text-start positions-details-table'>Limit Price</th>
                            <th className='text-start positions-details-table'>Order Type</th>
                            <th className='text-start positions-details-table'>Order Ref</th>
                            <th className='text-start positions-details-table'>Transmit</th>
                            <th className='text-start positions-details-table'>Status</th>
                            <th className='text-start positions-details-table'>Booked</th>
                            <th className='text-start positions-details-table'>Last Updated Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (     
                            <tr>
                                <td className='text-start positions-details-table'>{order.time}</td>
                                <td className='text-start positions-details-table'>{order.permId}</td>
                                <td className='text-start positions-details-table'>{order.orderId}</td>
                                <td className='text-start positions-details-table'>{order.conId}</td>
                                <td className='text-start positions-details-table'>{order.action}</td>
                                <td className='text-start positions-details-table'>{order.totalQuantity}</td>
                                <td className='text-start positions-details-table'><Currency>{order.lmtPrice}</Currency></td>
                                <td className='text-start positions-details-table'>{order.orderType}</td>
                                <td className='text-start positions-details-table'>{order.orderRef}</td>
                                <td className='text-start positions-details-table'>{order.transmit ? 'Yes' : 'No'}</td>
                                <td className='text-start positions-details-table'>{order.status}</td>
                                <td className='text-start positions-details-table'>{order.booked ? 'Yes' : 'No'}</td>
                                <td className='text-start positions-details-table'>{order.lastUpdateTime}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Text>
        </Card.Body>
    </Card>
  )
}

export default OrderList